<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" icon @click="setDrawer(!drawer)">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-spacer />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in accounts" :key="`item-${i}`">
            <v-list-item-title
              v-text="n.label"
              @click="handleFunctionCall(n.action)"
            />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";
// Utilities
import { mapState, mapMutations, mapActions } from "vuex";
import { LOGOUT } from "@/store/actions/account";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              );
            }
          }
        });
      }
    }
  },

  data: () => ({
    accounts: [{ label: "Logout", action: "logout" }]
  }),

  computed: {
    ...mapState({
      drawer: state => state.global.drawer
    })
  },

  methods: {
     ...mapActions({
      logout: `account/${LOGOUT}`,
    }),
    ...mapMutations({
      setDrawer: "global/SET_DRAWER"
    }),
    handleFunctionCall(function_name) {
      this[function_name]();
    }
  }
};
</script>
